import request from '../utils/request';

// 登录
export const login = query => {
  return request({
    url: '/kanban/api/clientLogin',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
