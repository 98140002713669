<template>
  <div class="login-wrap-login">
    <Top />

    <div class="login-mid">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item prop="username" label-width="0">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <el-input v-model="ruleForm.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item prop="password" label-width="0" class="el2">
          <div class="border_corner border_corner_left_top"></div>
          <div class="border_corner border_corner_right_top"></div>
          <div class="border_corner border_corner_left_bottom"></div>
          <div class="border_corner border_corner_right_bottom"></div>
          <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码"
            @keyup.enter="submitForm('ruleForm')"></el-input>
        </el-form-item>

        <el-form-item label-width="0">
          <el-button type="primary" @click="submitForm('ruleForm')">立即登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import "./Login.css";
import Top from "../../components/top.vue";
import { login } from "../../api/login";
import { getClientCfg } from "../../api/kanban";
import { ElMessage, ElLoading } from "element-plus";
export default {
  components: {
    Top,
  },
  data() {
    return {
      LoginInfoKey: "toilet-auto-login",
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  async created() {
    const acountInfo = localStorage.getItem(this.LoginInfoKey)
    if (acountInfo) {
      this.$nextTick().then(() => {
        Object.assign(this.ruleForm, JSON.parse(acountInfo))
        this.submitForm('ruleForm')
      })

      return
    }

    const token = localStorage.getItem("show_token")
    if (token) {
      const res = await getClientCfg()
      const loadingInstance = ElLoading.service({ text: "登陆中", fullscreen: true, background: "rgba(51,51,51, 0.8)" })
      if (res.code == 200) {
        this.initConfig({
          data: {
            clientName: res.data.clientName,
            clientId: res.data.id,
            token,
            type: res.data.deviceType
          }
        }, loadingInstance, 50)
      }
    }
  },

  methods: {
    submitForm(formName) {
      // console.log(this.$router)
      // this.$router.push('exhibition')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let query = {
            ...this.ruleForm,
          };
          // console.log(query,'query')

          const loadingInstance = ElLoading.service({ text: "登陆中", fullscreen: true, background: "rgba(51,51,51, 0.8)" })
          login(query).then((res) => {
            if (res.code == 200 && res.msg == "操作成功！") {
              localStorage.setItem(this.LoginInfoKey, JSON.stringify(query))
              this.initConfig(res, loadingInstance)
            } else {
              ElMessage.error(res.msg);
              loadingInstance.close()
            }
          }).catch(() => {
            loadingInstance.close()
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    initConfig(res, loadingInstance, time = 600) {
      this.$store.commit("changetitle", res.data.clientName);
      sessionStorage.setItem("a_title", res.data.clientName);
      sessionStorage.setItem("clientId", res.data.clientId);
      // 存一份token
      localStorage.setItem("show_token", res.data.token);
      //存等下要跳转的看板  type只有1消毒机看板  只有2一体机看板    1,2都有的是防疫大数据的看板
      sessionStorage.setItem("lookType", res.data.type);
      setTimeout(() => {
        this.$router.push(
          (res.data.type || []).includes(7)
            ? "toiletAntivirusDevice"
            : "contorlpage"
        );
        loadingInstance.close()
      }, 600);
    }
  },
};
</script>
<style scoped>
:deep().el-button--primary {
  padding: unset !important;
  width: 100%;
  height: 98px;
  background: rgba(79, 193, 180, 0.3);
  font-size: 24px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  border: unset;
  border-radius: unset;
}

:deep .el-input__inner {
  height: 72px;
  box-shadow: inset 0px 1px 55px 0px rgba(0, 172, 167, 0.37);
  border: 4px solid rgba(79, 193, 180, 0.37);
  font-size: 24px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  background-color: unset !important;
  text-align: center;
}

:deep .el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus,
.el-message-box__input div.invalid>input,
.el-message-box__input div.invalid>input:focus {
  border-color: rgba(79, 193, 180, 0.37);
}

:deep() input::-webkit-input-placeholder {
  color: rgba(8, 244, 218, 0.63);
}

:deep() input::-moz-placeholder {
  color: rgba(8, 244, 218, 0.63);
}

:deep() input:-moz-placeholder {
  color: rgba(8, 244, 218, 0.63);
}

:deep() input:-ms-input-placeholder {
  color: rgba(8, 244, 218, 0.63);
}

:deep() .el-form-item__error {
  color: rgba(79, 193, 180, 0.37);
}

.el2 {
  margin-top: 40px;
  margin-bottom: 75px;
}

.border_corner {
  z-index: 2500;
  position: absolute;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0);
  border: 4px solid #08f4da;
}

.border_corner_left_top {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
  border-top-left-radius: 4px;
}

.border_corner_right_top {
  top: 0;
  right: 0;
  border-left: none;
  border-bottom: none;
  border-top-right-radius: 4px;
}

.border_corner_left_bottom {
  bottom: 0;
  left: 0;
  border-right: none;
  border-top: none;
  border-bottom-left-radius: 4px;
}

.border_corner_right_bottom {
  bottom: 0;
  right: 0;
  border-left: none;
  border-top: none;
  border-bottom-right-radius: 4px;
}

.login-top {
  width: 100%;
  height: 99px;
  position: relative;
  box-sizing: border-box;
}

.login-wrap-login {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#011728, #142c3b, #011728) !important;
  padding-top: 25px;
}

.login-top-l {
  position: absolute;
  top: 15px;
  left: 40px;
}

.time1 {
  font-size: 32px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-weight: normal;
  color: #ffffff;
}

.time2 {
  font-size: 18px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #ffffff;
  margin-top: 8px;
}

.login-top-m {
  width: 1528px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background: url(../../assets/img/logintop.png) no-repeat;
  background-size: 100% 100%; */
}

.login-top-m .title {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 42px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  line-height: 58px;
}

.login-top-r {
  width: 308px;
  height: 49px;
  position: absolute;
  right: 60px;
  top: 30px;
  background: url(../../assets//img/rijiben.png) no-repeat;
  background-size: 100% 100%;
}

.login-mid {
  width: 551px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
